<script>
import store from "../simpleStore";
import withItems from "../withItems.vue";
import withGet from "../withGet.vue";
import Label from "./Label.vue";
import ListSelect from "./ListSelect.vue";
import adjustments from "./adjustments.js";

export default {
  props: [
    "value",
    "path",
    "item",
    "attKey",
    "params",
    "filters",
    "readOnly",
    "prefix",
    "suffix"
  ],
  components: {
    withItems,
    Label,
    ListSelect,
    withGet
  },
  data() {
    return {
      internalValue: null,
      childValue: null,
      baseUrl: process.env.VUE_APP_PRESTO_CORE_API || false
    };
  },
  computed: {
    placeholder() {
      return (
        this.att &&
        "x-default-placeholder" in this.att &&
        this.att["x-default-placeholder"]
      );
    },
    placeholderDescription() {
      return (
        this.att &&
        "x-default-description" in this.att &&
        this.att["x-default-description"]
      );
    },
    hasChanged() {
      return this.original && !!(this.original != this.value);
    },
    schemas() {
      return store.schemas || {};
    },
    schema() {
      const schema =
        store.schemas && store.schemas[this.path]
          ? store.schemas[this.path]
          : false;
      return schema;
    },
    att() {
      return (
        this.schema &&
        this.schema.properties &&
        this.schema.properties[this.attKey]
      );
    },
    required() {
      return (
        this.schema &&
        this.schema.required &&
        this.schema.required.includes(this.attKey)
      );
    },
    max() {
      return (
        adjustments &&
        adjustments.maximums &&
        !!(this.path in adjustments.maximums) &&
        !!(this.attKey in adjustments.maximums[this.path]) &&
        adjustments.maximums[this.path][this.attKey]
      );
    },
    min() {
      return (
        adjustments &&
        adjustments.minimums &&
        !!(this.path in adjustments.minimums) &&
        !!(this.attKey in adjustments.minimums[this.path]) &&
        adjustments.minimums[this.path][this.attKey]
      );
    },
    isCalculatedField() {
      return (
        adjustments && 
        adjustments.calculatedFields &&
        !!(this.path in adjustments.calculatedFields) &&
        !!(this.attKey in adjustments.calculatedFields[this.path])
      )
    },
    calculatedField() {
      // either boolean, or the result of the function ( which could  also be a boolean  )
      const value = (
        adjustments && 
        adjustments.calculatedFields &&
        !!(this.path in adjustments.calculatedFields) &&
        !!(this.attKey in adjustments.calculatedFields[this.path]) &&
        adjustments.calculatedFields[this.path][this.attKey]( this.item ) // call it as a function and return the value
      )
      // we set the inetrnal value on the fly:
      // this shoudl ensure this field is always up to dayt based on when the item changes
      this.internalValue = value ? value : ''
      return value
    }
  },
  created() {
    this.original = this.value;
    if (this.value !== null && this.value !== undefined) {
      this.internalValue = this.value;
    }
  },
  watch: {
    // when the supplied value for the input is changed, we set it as internal state
    // this lets us fully control when and where a change is propogated up again. 
    value(val) {
      this.internalValue = val;
    },
    childValue(val) {
      this.$emit("input", val); // this syncs internValue with the v-model on this component
    },
    internalValue(val) {
      // handle maximum and minimum values by just overriding the entered value
      if (this.max && Math.ceil(val) > this.max) {
        val = Math.ceil(this.max);
      }
      if (this.min && Math.floor(val) < this.min) {
        val = Math.floor(this.min);
      }
      this.childValue = val;
    }
  },
  methods: {
    generateRules(key, items = false) {
      // return an array of validation rules that
      // receive a key and return the rules based on the schema variables
      const rules = [];
      const prefixedVal = val =>
        String(val) + (this.prefix || "") + (this.suffix || "");

      if (this.required) {
        rules.push(value => !!prefixedVal(value) || "This field is required");
      }
      if (items && "x-unique" in this.att) {
        rules.push(
          value =>
            !Object.values(items)
              .filter(v => v != null) // Remove null values
              .map(i =>
                String(i[key])
                  .trim()
                  .toLowerCase()
              )
              .filter(
                i =>
                  i !=
                  String(this.original)
                    .trim()
                    .toLowerCase()
              ) // ensure we dont match against the current item
              .includes(
                String(value)
                  .trim()
                  .toLowerCase()
              ) || `The ${key} field must be unique.`
        );
      }
      if ("maxLength" in this.att) {
        rules.push(
          value =>
            String(value).length <= parseInt(this.att["maxLength"]) ||
            `The ${key} field must be fewer than ${this.att["maxLength"]} characters`
        );
      }
      if (key.toLowerCase() == "latitude") {
        rules.push(
          value =>
            parseInt(value) < 90 || "Longitude must be below 90.00 degrees"
        );
        rules.push(
          value =>
            parseInt(value) > -90 || "Longitude must be above -90.00 degrees"
        );
        rules.push(
          value =>
            (String(value).split(".").length > 0 &&
              String(value).split(".")[1].length < 4) ||
            "Latitude cannot have more than three decimal places"
        );
      }
      if (key.toLowerCase() == "longitude") {
        rules.push(
          value =>
            parseInt(value) < 180 || "Latitude must be below 180.00 degrees"
        );
        rules.push(
          value =>
            parseInt(value) > -180 || "Latitude must be above -180.00 degrees"
        );
        rules.push(
          value =>
            (String(value).split(".").length > 0 &&
              String(value).split(".")[1].length < 4) ||
            "Longitude cannot have more than three decimal places"
        );
      }
      // if (this.att.type == "integer") {
      //   rules.push(
      //     value =>
      //       (!!value && !isNaN(parseInt(String(value)))) ||
      //       "Input must be a valid integer"
      //   );
      // }

      return rules;
    }
  }
};
</script>

<template>
  <div>
    <!-- SPECIFIC FIELD OVERRIDES -->
    <div v-if="attKey == 'netbox_id'">
      <!-- Netbox Id not shown here -->
    </div>
    <div v-else-if="attKey == 'service_auto_id'">
      <!-- Show nothing: service ID is handled on right alongside Netbox ID -->
    </div>
    <div v-else-if="attKey == 'billeasy_uuid'">
      <!-- Choose a Billeasy ID from the list -->
      <withGet 
          :url="`${baseUrl}connect/company?page_size=10000`"
          v-slot="{items:companies}"
          >
          <v-autocomplete
            label="Choose a Connect / Billeasy Company"
            placeholder="Select a company"
            :items="Object.values(companies).filter(c => !!c.billeasy_id).map( c => ({'value': `${c.billeasy_id}`, 'text': `${c.companyname} [ ${c.presto_id || 'No Presto ID Set'} ]` }))"
            >
          </v-autocomplete>
      </withGet>
    </div>
    <div v-else-if="path == 'device_interface' && attKey == 'parent_interface' && item.device">
      <!-- Turns the device_interface field into a dropdown based on its parent device -->
      <!-- this only works if a device_interface has been accessed from a parent device -->
      <withItems
        path="device_type"
        :id="String(item.device.device_type_id)"
        v-slot="{ item: device_type }"
      >
        <v-autocomplete
          :items="device_type.usable_interfaces.split(',')"
          outlined
          v-model="internalValue"
          autocomplete="null"
        >
          <template v-slot:label>
            <Label
              :required="true"
              :attKey="attKey"
              label="Select a PARENT_INTERFACE"
            >
            </Label>
          </template>
          <v-icon slot="prepend"> mdi-link-plus </v-icon>
        </v-autocomplete>
      </withItems>
    </div>
    <div v-else-if="attKey == 'connect_project_id'">
      <div v-if="!item.site">
        <v-text-field class="pb-4" readonly>
          <template v-slot:label>
            <Label
              :required="false"
              label="Select a Site to populate the list of Connect Projects"
            >
            </Label>
          </template>
          <v-icon slot="prepend"> mdi-link-plus </v-icon>
        </v-text-field>
      </div>
      <div v-else class="pb-4">
        <withItems
          path="customer"
          :id="String(item.site.customer_id)"
          v-slot="{ item: customer }"
        >
          <withGet
            :url="
              `${baseUrl}connect/company?presto_id=${customer.partner_id}`
            "
            v-slot="{ items: company }"
          >
            <v-alert v-if="!company || !('id' in company)">
              Cannot find Connect Company with Presto ID:
              {{ customer.partner_id }}<br />
              <small
                >You will be unable to link this VLAN to Connect. Contact your
                Administrator.</small
              ><br />
            </v-alert>
            <div v-else>
              <withGet
                :url="
                  `${baseUrl}connect/lookupproject?status=accepted&customer=${company.id}`
                "
                v-slot="{ items }"
              >
                <v-autocomplete
                  :items="
                    Object.values(items).map(i => ({
                      value: i.id,
                      text: `${i?.company?.companyname || ''} ${i.order ||
                        ''} ${i.title || ''}`
                    }))
                  "
                  outlined
                  item-text="text"
                  item-value="value"
                  v-model="internalValue"
                  autocomplete="null"
                >
                  <template v-slot:label>
                    <Label label="Select a Connect Project"> </Label>
                  </template>
                  <v-icon slot="prepend"> mdi-link-plus </v-icon>
                </v-autocomplete>
              </withGet>
            </div>
          </withGet>
        </withItems>
      </div>
    </div>
    <div v-else-if="attKey == 'connect_line_id'">
      <!-- Only show connect_line_id if a project has been selected -->
      <div v-if="item.connect_project_id">
        <withGet
          :url="
            `${baseUrl}connect/projectline?project_id=${item.connect_project_id}`
          "
          v-slot="{ items }"
        >
          <v-autocomplete
            :items="
              Object.values(items).map(i => ({
                value: i.id,
                text: `#${i.id} ${i.name}`
              }))
            "
            outlined
            item-text="text"
            item-value="value"
            v-model="internalValue"
            autocomplete="null"
          >
            <template v-slot:label>
              <Label :required="false" label="Select a Connect Project Line">
              </Label>
            </template>
            <v-icon slot="prepend"> mdi-numeric-1-box</v-icon>
          </v-autocomplete>
        </withGet>
      </div>
    </div>
    <!-- END OF SPECIFIC FIELD OVERRIDES -->

    <div v-else-if="isCalculatedField">
      <v-text-field
          class="mb-6"
          :value="calculatedField"
          :rules="generateRules(attKey)"
          :prefix="prefix || ''"
          :suffix="suffix || ''"
          readonly
          disabled
        >
          <template v-slot:label>
            <Label :required="required" :attKey="attKey"></Label>
          </template>
          <v-icon slot="prepend">
            mdi-cog
          </v-icon>
          <div slot="append"> 
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="blue darken-2">mdi-information</v-icon>
              </template>
              <div class="pa-2" style="max-width: 200px;">This field is automatically generated from others in the form, and cannot be edited manually. </div>
            </v-tooltip>
          </div>
        </v-text-field>
    </div>
    <div v-else-if="'x-path' in att">
      <!-- this is a foreign key field -->
      <template v-if="!!(att['x-path'] in schemas)">
        <ListSelect
          :attKey="attKey"
          v-model="internalValue"
          :rules="generateRules(attKey)"
          :path="att['x-path']"
          :readonly="readOnly && readOnly"
          :required="required"
        />
      </template>
      <template v-else>
        <v-text-field v-model="internalValue" :rules="generateRules(attKey)">
          <template v-slot:label>
            <Label :required="required" :attKey="attKey" />
          </template>
          <v-icon slot="prepend"> mdi-link-plus </v-icon>
        </v-text-field>
        <v-alert type="warning"
          ><strong>{{ `${att["x-path"]}`.toUpperCase() }}</strong> cannot be
          show as a dropdown: enter an ID</v-alert
        >
      </template>
    </div>
    <div v-else-if="'x-unique' in att">
      <withItems :path="path" v-slot="{ items }">
        <v-text-field
          class="mb-2"
          outlined
          v-model="internalValue"
          :rules="generateRules(attKey, items)"
          :prefix="prefix || ''"
          :suffix="suffix || ''"
          :readonly="readOnly && readOnly"
        >
          <template v-slot:label>
            <Label :required="required" :attKey="attKey" />
          </template>
          <v-icon slot="prepend">
            mdi-numeric-1-box
          </v-icon>
        </v-text-field>
      </withItems>
    </div>
    <div v-else>
      <div v-if="['latitude', 'longitude'].includes(attKey.toLowerCase())">
        <v-text-field
          v-model="internalValue"
          type="number"
          placeholder="(+/-)##.######"
          :rules="generateRules(attKey)"
        >
          <template v-slot:label>
            <Label :required="required" :attKey="attKey" />
          </template>
          <v-icon slot="prepend"> mdi-map-marker </v-icon>
        </v-text-field>
      </div>

      <div v-else-if="att.type == 'boolean'">
        <v-checkbox v-model="internalValue">
          <template v-slot:label>
            <Label :required="required" :attKey="attKey" />
          </template>
          <v-icon slot="prepend">
            mdi-checkbox-marked-outline
          </v-icon>
        </v-checkbox>
      </div>

      <div v-else-if="att.type == 'integer'">
        <v-text-field
          v-model.number="internalValue"
          type="number"
          :min="min"
          :max="max"
          :rules="generateRules(attKey)"
        >
          <template v-slot:label>
            <Label :required="required" :attKey="attKey" />
          </template>
          <v-icon slot="prepend"> mdi-counter </v-icon>
        </v-text-field>
      </div>

      <div v-else-if="!!('enum' in att)">
        <v-select
          :items="att.enum"
          outlined
          v-model="internalValue"
          :rules="generateRules(attKey)"
        >
          <template v-slot:label>
            <Label :required="required" :attKey="attKey" />
          </template>
          <v-icon slot="prepend"> mdi-select </v-icon>
        </v-select>
      </div>

      <div v-else>
        <!-- Simple Text as backup -->
        <v-text-field
          v-model="internalValue"
          :rules="generateRules(attKey)"
          :prefix="prefix || ''"
          :suffix="suffix || ''"
          :readonly="readOnly && readOnly"
        >
          <template v-slot:label>
            <Label :required="required" :attKey="attKey" />
          </template>
          <v-icon slot="prepend"> mdi-pencil </v-icon>
        </v-text-field>
      </div>
    </div>
  </div>
</template>
