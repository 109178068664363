
import axios from "axios";
import store from "./simpleStore";
/* 
This is a universal wrapper component that performs API calls and 
exposes it to child components via slots
This is a simple way to ensure that any child components will ALWAYS 
have the data they need to function: we dont need to include errop checking 
and various store lookups within the children, it always JUST WORKs

to use this component, wrap your template content in it, making sure to use the 
v-slow attribute to expose the generated data to your template, like this: 

*/

const props = {
  url: {
    type: String,
    required: true
  },
  params: {
    type: Object,
    required: false,
    default: () => ({})
  },
  errorOnEmpty: {
    type: Boolean,
    required: false,
    default: true
  }
};

export default {
  props,
  data() {
    return {
      items: {},
      isLoading: false,
      error: false,
      interval: false
    };
  },
  computed: {
    isReady() {
      return !this.isLoading && this.items;
    },
  },
  methods: {
    async fetchData() {
      const params = this.params;
      this.isLoading = true;
      this.error = false;
      const storeLoading = !!(this.url in store.loadingPaths);
      if (storeLoading) {
        this.interval = setTimeout(() => this.fetchData(), 1000); // run every second until the request completes
        return;
      }

      //first we check if the value is already in the store
      let found = {};
      let paramString = new URLSearchParams(this.params).toString();
      let url = `${this.method}:${this.url}?${paramString}`;

      if (url in store.paths) {
        found = store.paths[url];
      }
      
      if (Object.keys(found).length) {
        if (Array.isArray(found)) {
          this.items = [...found];
        } else {
          Object.assign(this.items, { ...found });
        }
        this.hasItems = !!this.items;
        this.isLoading = false;
        console.log('Found data in store, returning it', this.items )
        delete store.loadingPaths[url];
        this.$emit("done", found);
        return;
      }

      // if we got here, the path is not in store so we need to add it
      try {
        // first we flag the path as being fetched in the store
        store.loadingPaths[url] = true;

        const response = await axios.get(this.url, { params })

        console.log(`${url} response.data`, response.data);

        let fresh: Record<string, any> = response.data || {};

        // if we are getting an ID endpoint and get no data, somethign went terribly wrong
        if (this.id && !Object.keys(fresh).length) {
          throw new Error("No data returned.");
        }

        // now we assign the data to items, in order to keep reactivity
        if (Array.isArray(fresh)) {
          this.items = [...fresh];
        } else {
          Object.assign(this.items, { ...fresh });
        }
        // if we fetched data, save it to the store for reference by other instances of the component
        store.paths[url] = { ...fresh }; // destructure here to avoid any pass by reference shenanigans
        // emit the data as an event so we can catch it and add to the parent if we need to
        this.$emit("done", fresh);
      } catch (error) {
        console.error(error?.response?.data || error);
        this.items = {};
        this.error = error?.response?.data || error;
      }

      this.hasItems = !!Object.keys(this.items).length;
      this.isLoading = false;

      //console.log('clearing loading interval')
      delete store.loadingPaths[url]; // clear loading cache
      clearTimeout(this.interval); // clear internal interval
    }
  },
  watch: {
    path: {
      immediate: true, // so it's executed when component is created
      handler: function() {
        this.fetchData();
      }
    }
  }
};
